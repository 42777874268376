<div class="body-content">
	<div class="container" style="min-height: 450px;">
        <div class="checkout-box ">
            <div class="row">
				<div class="col-xs-8 col-sm-8 col-md-8">
                    <h2>Your Addresses</h2>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4" style="top: 20px;">
                </div>
            </div>
			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-4">
					<div style="border-style: dashed;border-radius: 20px;border-color: darkgrey;min-height: 250px;background-color:white"  class="panel-group checkout-steps" id="accordion">
                        <a routerLink="/address">
                        <mat-card  class="example-card center-cls" >
                            <mat-card-header>
                                <mat-card-title style="font-size: 30px;">Add</mat-card-title>
                            </mat-card-header>
                        </mat-card></a>
					</div><!-- /.checkout-steps -->
                </div>
                
                <div class="col-xs-12 col-sm-12 col-md-4" *ngFor="let add of addressList">
					<div  style="border-style: solid;border-radius: 20px;border-color: darkgrey;min-height: 250px;background-color:white" class="panel-group checkout-steps" id="accordion">
                       <div class="row">
                        <div class="col-xs-5 col-sm-5 col-md-5" style="margin-left:5px;line-height:3;font-size: 15px;font-weight: bold;">Deliver Here</div>
                        <div class="col-xs-4 col-sm-4 col-md-4" style="font-size: 15px;line-height:3;font-weight: bold;" (click)="selectAddress(add)">
                            <button class="btn btn-primary" routerLink="/finalcart" *ngIf="add.checked">Continue</button> 
                        </div>
                       <div class="col-xs-2 col-sm-2 col-md-2" style="font-size: 17px;line-height:3;font-weight: bold;" (click)="selectAddress(add)">
                        <mat-radio-button  [checked]="add.checked" style="float:right;">   </mat-radio-button>
                        </div>
                    </div> 
                       <mat-divider></mat-divider>
                        <mat-card class="example-card">
                            <mat-card-header>                               
                                <div mat-card-avatar class="example-header-image"><i class="fa fa-house"></i></div>
                                <mat-card-title>{{add.name}}</mat-card-title>
                                <mat-card-subtitle>{{add.address}}</mat-card-subtitle>
                            </mat-card-header>
                        </mat-card>
					</div><!-- /.checkout-steps -->
                </div>
			</div>
        </div>
    </div>
</div>
<app-loader [show]="showLoader"></app-loader>

     
        

