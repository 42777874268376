<form [formGroup]="registerForm" (ngSubmit)="callForSubmit()">
    <div class="form-group">
        <label>Enter Name</label>
        <input type="text" [(ngModel)]="name"  formControlName="firstName"maxlength="30"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required" class="red-color">Name is required</div>
        </div>
    </div>
    <div class="form-group">
        <label>Email</label>
        <input type="text" [(ngModel)]="email"  formControlName="email" maxlength="30"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required" class="red-color">Email is required</div>
            <div *ngIf="f.email.errors.email" class="red-color">Email must be a valid email address</div>
        </div>
    </div>
    
    <div class="form-group" style="text-align: center;margin-bottom: 0px;">
        <button class="btn btn-primary">Continue</button>
    </div>
</form>


  <app-loader [show]="showLoader"></app-loader>