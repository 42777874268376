import { Component, Input, OnInit } from '@angular/core';
import { CommonServicesService } from '../common-services.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-image-auth',
  templateUrl: './image-auth.component.html',
  styleUrls: ['./image-auth.component.scss']
})
export class ImageAuthComponent implements OnInit {
  @Input() src: string;
  @Input() alt = '';
  cssValue:any;
  @Input() name:any;
  @Input() show:boolean;
  subscription: Subscription;
  default="assets/images/default.png";
  constructor(private commonServices:CommonServicesService) {
   }

  ngOnInit() {
    this.cssValue='img-size';
    if(this.name=='details'){
      this.cssValue="img-size2";
    }
    else if(this.name=="cart"){
      this.cssValue="img-size3"
    }
    else if(this.name=="storeImage"){
      this.cssValue="img-size4"
    }
    else if(this.name=="circl"){
      this.cssValue="img-circl"
    }
    this.subscription = this.commonServices.getload().subscribe(x => {  
      this.show=x; 
    });
  }

}
