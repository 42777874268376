import { Component, OnInit,Input } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() show: boolean = true;
  constructor() { }

  ngOnInit() {
    $(window).scroll(function() {
      var $myDiv = $('#myDiv');
      var st = $(this).scrollTop();
      //var wh=$(window).height()*1.5;
      st=st*3;
      $myDiv.height(st);
      
  }).scroll();
  }

}
