import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  message:any;
  type:any;
  callFrom:any;
  constructor(private router: Router,public dialogRef: MatDialogRef<MessageComponent>,@Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message=data.message;
    this.type=data.type;
    this.callFrom=data.finalCart;
   }

  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
    if(this.callFrom=="finalCart" && this.type=="success"){
      this.router.navigate(['/home']);
    }
    else  if(this.callFrom=="CartAdded" && this.type=="success"){
      this.router.navigate(['/home']);
    }
    else{
      this.router.navigate(['/finalcart']);
    }
  }
  chekout(){
    this.dialogRef.close();
    this.router.navigate(['/checkout']);
  }

}
