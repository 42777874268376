import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonServicesService } from '../common-services.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  datauser:any;
  otp:any;
  subscription: Subscription;
  gotOTP:boolean;
  userOtp:any;
  showLoader:boolean;
  errorSeller:boolean;
  constructor(public dialogRef: MatDialogRef<PopupComponent>,public commonServices:CommonServicesService) { }

  ngOnInit() {
    this.subscription = this.commonServices.getOTPStatus().subscribe(x => {  
      this.errorSeller=false;
      if(x.msg){
        this.errorSeller=x.msg;
      }
      else if(x)this.onNoClick();
    });
  }
  onNoClick(): void {
    this.datauser=undefined;
    this.userOtp=undefined;
    this.dialogRef.close();
  }
  callForOTP(){
    var user={}
    if(!this.datauser){
      return;
    }
    if(this.commonServices.otp && (this.userOtp==undefined || this.userOtp=="")){
      return;
    }
    if(this.commonServices.otp){
      user={
        "username":this.datauser,
        "otp":this.userOtp
      }
    }
    else{
      user={
        "username":this.datauser
      }
    }
    this.commonServices.getLogin(user);
  }
  flushError(){
    this.errorSeller=false;
  }

}
