import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { ApiService } from './services/api.service';
import { StoreService } from './store.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private subjectFOrHome = new Subject<any>();
  constructor(private apiService:ApiService,private cookeiServi:CookieService,private storeServices:StoreService) { }
  async  getSomeData() {
    localStorage.clear();
    var parsedBase64Key = CryptoJS.enc.Base64.parse("VGhlQmVzdFNlY3JldEtleQ==");
    var encryptedCipherText =this.replaceAll(this.cookeiServi.get('accessToken'),'PLUS','+'); 
    if(!encryptedCipherText){
      window.location.href="https://www.bybuy.in/";
    }
    var decryptedData = CryptoJS.AES.decrypt( encryptedCipherText, parsedBase64Key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
    } );
    var decryptedText = decryptedData.toString( CryptoJS.enc.Utf8 );
    sessionStorage.setItem('sellerDetail',decryptedText);
    var requestParams={
      "mobileno":JSON.parse(sessionStorage.getItem("sellerDetail")).sellerMobileNo
    }
    //8318797581 for prod JSON.parse(sessionStorage.getItem("sellerDetail")).sellerMobileNo
    await this.apiService.getShopDetail(name, requestParams).subscribe((res: any) => {
      if(res.code=="200"){
        sessionStorage.setItem("sellerDetail2",JSON.stringify(res.data)); 
        this.storeServices.storeDetails=res;
        res.data.deliveryCharge ? this.storeServices.storeServices.push(('DELIVERY CHARGES \u20B9' + res.data.deliveryCharge)) : this.storeServices.storeServices.push('FREE HOME DELIVERY');
        if(res.data.minOrderValue){
          this.storeServices.storeServices.push(('MIN ORDER VALUE \u20B9'+res.data.minOrderValue));
        } 
        if(res.data.shopType=="STORE"){
          this.storeServices.storeServices.push("LOCAL STORE");
        }
        else if(res.data.shopType){
          this.storeServices.storeServices.push(res.data.shopType +" STORE");
        } 
        if(res.data.acceptedPaymentType=="ALL"){
          this.storeServices.storeServices.push('PAY ON DELIVERY');
          this.storeServices.storeServices.push('ONLINE PAYMENT');
        }
        else if(res.data.acceptedPaymentType=="POD"){
          this.storeServices.storeServices.push('PAY ON DELIVERY');
        }
        else if(res.data.acceptedPaymentType=="PG"){
          this.storeServices.storeServices.push('ONLINE PAYMENT');
        }
        this.subjectFOrHome.next(true);
      }
     
      },(err)=>{
         console.log(err);
       });  
  }
  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }
  getHome(): Observable<any> {
    return this.subjectFOrHome.asObservable();
  }
  
}
