import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonServicesService } from '../common-services.service';
import {ApiService} from '../services/api.service'
import {PopupComponent} from '../popup/popup.component'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { AppConfigService } from '../app-config.service';
import { StoreService } from '../store.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  showLoader:boolean;
  subscription: Subscription;
  SlideOptions = { items: 2, dots: true, nav: false };  
  SlideOptions2 = { items: 6, dots: false, nav: true }; 
  productList: any;
  searchParams={
    "page":0,
    "isSellerActive":true,
    "latitude":JSON.parse(sessionStorage.getItem("sellerDetail2")).latitude,
    "longitude":JSON.parse(sessionStorage.getItem("sellerDetail2")).longitude,
    "search":"",
    "sellerid":JSON.parse(sessionStorage.getItem("sellerDetail2")).sellerid,
    "isProductActive":true,
    "size":10
  }
  params={
    "sellerid":JSON.parse(sessionStorage.getItem("sellerDetail2")).sellerid,
    "categoryid":'',
    "isSellerActive":true,
    "page":0,
    "size":10,
    "isProductActive":true
  }
  subsFlag: any=false;
  categoryData: any;
  finalCategoryId: any;
  targetElement: any;
  stopCalling: boolean;
  hideCategory: boolean;
  storeOfferDiscPer: any;
  constructor(public storeServices:StoreService,public dialog: MatDialog,private apiService:ApiService,public commonServices:CommonServicesService,private router: Router) { }
  ngOnInit() {
           this.stopCalling=false;
           this.hideCategory=false;
           this.targetElement = document.querySelector('#top-banner-and-menu');
           this.callProductApi(false);
           this.categoryApi();
           this.isSubsribed();
           this.storeOffer();
           this.subscription = this.commonServices.getData().subscribe(x => {   
             this.stopCalling=false
             this.searchParams.search=x; 
             this.searchParams.page=0; 
             this.commonServices.searchPage=undefined;
             if(x==undefined || x==""){this.params.page=0;this.callProductApi(false);}
             else this.searchProduct(false);   
               this.router.navigate(['/home']);
           });
           this.subscription = this.commonServices.getSubscription().subscribe(x => { 
             this.subscribe(); 
           });
  }
  onScrollDown() {
    if(this.params.page==0)this.params.page=1;
    if(this.searchParams.search)this.searchProduct(true);
    else this.callProductApi(true);

   }
   isSubsribed(){
     if(!(JSON.parse(sessionStorage.getItem('buyerInfo')))) return;
     var subParam={
      "buyerId":JSON.parse(sessionStorage.getItem('buyerInfo')).buyerid,
      "sellerId":JSON.parse(sessionStorage.getItem("sellerDetail2")).sellerid
     }
     this.showLoader=true;
    this.apiService.getSubcription(name, subParam).subscribe((res: any) => {
      this.subsFlag=res.data
      this.showLoader=false;
    },(err)=>{
      this.showLoader=false;
       console.log(err);
     });
   }
   callProductApi(callonScroll){     
    this.hideCategory=false;
     if(this.stopCalling) return;
    this.showLoader=true;
    this.params.categoryid="";    
   disableBodyScroll(this.targetElement);
    if(this.finalCategoryId>0){
      this.params.categoryid=this.finalCategoryId;
    }
    this.apiService.getproductData(name, this.params).subscribe((res: any) => {
      this.showLoader=false;
      enableBodyScroll(this.targetElement);
      if(res.code=="200"){
        if(this.params.page==0)this.params.page=1;
        if(callonScroll)
        {  this.params.page=this.params.page+1;
          this.productList=this.productList.concat(res.data.content);
        }
        else {
          this.productList=res.data.content;
        }
        this.stopCalling=false;
        if(res.data.content.length==0){
          this.stopCalling=true;
        }
        
      }
     },(err)=>{
      this.showLoader=false;
      enableBodyScroll(this.targetElement);
       console.log(err);
       window.location.href="https://www.bybuy.in/"
     }); 
   }

   searchProduct(callonScroll){
     this.hideCategory=true;
    if(this.stopCalling) return;
    this.showLoader=true;
    this.searchParams.latitude=JSON.parse(sessionStorage.getItem("sellerDetail2")).latitude;
    this.searchParams.longitude=JSON.parse(sessionStorage.getItem("sellerDetail2")).longitude
    disableBodyScroll(this.targetElement);
    this.apiService.getSearchedproductData(name, this.searchParams).subscribe((res: any) => {
      this.showLoader=false;
      enableBodyScroll(this.targetElement);
      if(this.searchParams.page==0)this.searchParams.page=this.searchParams.page+1;   
      if(callonScroll)
      {     
        this.searchParams.page=this.searchParams.page+1;   
        this.productList=this.productList.concat(res.data.content);
      }
      else {
        this.productList=res.data.content;
      }  
      this.stopCalling=false;
        if(res.data.content.length==0){
          this.stopCalling=true;
        }    
     },(err)=>{
      this.showLoader=false;
      enableBodyScroll(this.targetElement);
       console.log(err);
       window.location.href="https://www.bybuy.in/"
     }); 
   }
   setProdDetail(proddetail){
    sessionStorage.setItem('prodId', proddetail.productid);
   }
   subscibeCheck(){
     if(!sessionStorage.getItem('buyerInfo')){
      this.commonServices.otp=false;
      this.commonServices.callFrom="subsciption";
      const dialogRef = this.dialog.open(PopupComponent, {
        width: '250px',
        data: {name: "", animal: ""}
      });
     }
     else{
       this.subscribe();
     }
   }
   subscribe(){
    var params={
      'buyerId':JSON.parse(sessionStorage.getItem('buyerInfo')).buyerid,
      'sellerId':JSON.parse(sessionStorage.getItem("sellerDetail2")).sellerid
    }
    this.showLoader=true;
    this.apiService.saveSubscribe(name, params).subscribe((res: any) => {
      this.showLoader=false;
      this.isSubsribed();
    },(err)=>{
      this.showLoader=false;
       console.log(err);
     });
   }
   categoryApi(){
    var params={
      'sellerId':JSON.parse(sessionStorage.getItem("sellerDetail2")).sellerid
    }
    this.showLoader=true;
    this.apiService.getCategories(name, params).subscribe((res: any) => {
      if(res.code==200){
        this.categoryData=[
        
          {
          "categoryid": -1,
          "name": "All",
          "categoryImage": "",
          "carousalImage": "",
          "bgColorHexCode": "#77d8d8",
          "appDisplayPriorityNumber": null,
          "createdOn": 1599653399346,
          "modifiedOn": 1599653399346
         }]
        this.categoryData=this.categoryData.concat(res.data);
      }
      
      this.showLoader=false;
    },(err)=>{
      this.showLoader=false;
       console.log(err);
     });
   }
   callCatProduct(event){
    this.stopCalling=false;
     this.finalCategoryId=this.categoryData[event.index].categoryid;
     this.params.page=0;
     this.searchParams.page=0;
     if(this.searchParams.search)this.searchProduct(false);
     else this.callProductApi(false);
    
   }
   storeOffer(){
    this.showLoader=true;
    var params={
      'sellerId':JSON.parse(sessionStorage.getItem("sellerDetail2")).sellerid,
      'isActive':true
    }
    this.apiService.getStoreOffer(name, params).subscribe((res: any) => {
      if(res.code==200){
        this.storeOfferDiscPer=res.data;
      }      
      this.showLoader=false;
    },(err)=>{
      this.showLoader=false;
       console.log(err);
     });
   }
   unSubscribe(){
    var params={
      'buyerId':JSON.parse(sessionStorage.getItem('buyerInfo')).buyerid,
      'sellerId':JSON.parse(sessionStorage.getItem("sellerDetail2")).sellerid
    }
    this.showLoader=true;
    this.apiService.saveUnSubscribe(name, params).subscribe((res: any) => {
      this.showLoader=false;
      this.isSubsribed();
    },(err)=>{
      this.showLoader=false;
       console.log(err);
     });
   }
    
}
