<div mat-dialog-content>
  <mat-form-field style="width: 100%;">
    <mat-label>Enter Mobile Number</mat-label>
    <input matInput (keyup)="flushError();" [disabled]="commonServices.otp" [(ngModel)]="datauser" type="text"  [restrict-input]="'numberOnly'" enableValidation="true" required="required" min="10" max="10" class="input-field search-field" placeholder="Enter Mobile Number">
  </mat-form-field>
  <span *ngIf="errorSeller" style="color: red;font-size: 12px;width: 100%;display: block;text-align: justify;">{{errorSeller}}</span>
  <mat-form-field *ngIf="commonServices.otp" style="width: 100%;">
    <mat-label>Enter OTP</mat-label>
    <input matInput [(ngModel)]="userOtp" type="text" id="number"   [restrict-input]="'numberOnly'" enableValidation="true" required="required"  class="input-field search-field" placeholder="Enter OTP">
  </mat-form-field>
</div>
<div mat-dialog-actions style="text-align: center;display: block;">
   <button mat-button  cdkFocusInitial (click)="callForOTP()" *ngIf="!commonServices.otp" >Get OTP</button>
   <button mat-button  cdkFocusInitial (click)="callForOTP()" *ngIf="commonServices.otp">Continue</button>
</div>
<app-loader [show]="commonServices.loader"></app-loader>