import { Component, ElementRef} from '@angular/core';
import { MatDialog } from '@angular/material';
import { CommonServicesService } from '../common-services.service';
import { MessageComponent } from '../message/message.component';
import { ApiService } from '../services/api.service';
import * as CryptoJS from 'crypto-js';
import { HttpClient} from '@angular/common/http';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BuyerinfoComponent } from '../buyerinfo/buyerinfo.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-finalcart',
  templateUrl: './finalcart.component.html',
  styleUrls: ['./finalcart.component.scss']
})
export class FinalcartComponent {
  showLoader:boolean;
  finalCartList: any;
  deliveryAddress: any;
  fullResponse: any;
  shopDetail: any;
  outOfStock: boolean;
  payOnDelivery:boolean=true;
  onlinePay:boolean=false;
  subscription: Subscription;
  posting = false;
  @ViewChild('myFormPost',{static: true}) myFormPost: ElementRef;
  postData: { appId: string; orderId: string; orderAmount: number; orderCurrency: string; orderNote: string; customerName: string; customerEmail: string; customerPhone: string; returnUrl: string; notifyUrl: string;signature:string ;};
  generatedOrder: any;
  showPOD: boolean;
  showPG: boolean;
  constructor(private router: Router,private http: HttpClient,private apiService:ApiService,public commonServices:CommonServicesService,public dialog: MatDialog) { }

  ngOnInit() {
    this.showPOD=false;
    this.showPG=false;
    this.payOnDelivery=true;
    var offerparam={buyerId:JSON.parse(sessionStorage.getItem('buyerInfo')).buyerid,sellerId:JSON.parse(sessionStorage.getItem("sellerDetail2")).sellerid,deliveryAddressId:JSON.parse(sessionStorage.getItem('selectedAddress')).addressid};
    this.showLoader=true;
    this.finalCartList=[];
    this.subscription = this.commonServices.getBuerinfoCall().subscribe(x => {  
      this.paymentMode('ONP');
    });
    this.apiService.getFinalItem(name, offerparam).subscribe((res: any) => {
      this.showLoader=false;
      this.outOfStock=false;
      if(res.code=="200"){
        for(var i=0;i<res.data.sellerDetails[0].categories.length;i++){
          for(var j=0;j<res.data.sellerDetails[0].categories[i].items.length;j++){
            if(res.data.sellerDetails[0].categories[i].items[j].outOfStock){
              this.outOfStock=true;
            }
            this.finalCartList.push(res.data.sellerDetails[0].categories[i].items[j]);
          }
        }
        this.fullResponse=res.data;
        this.shopDetail=res.data.sellerDetails[0];
        if(this.shopDetail.acceptedPaymentType=="ALL"){
          this.showPOD=true;
          this.showPG=true;
                
        }
        else if(this.shopDetail.acceptedPaymentType=="POD"){
          this.showPOD=true;
          this.showPG=false;
        }
        else if(this.shopDetail.acceptedPaymentType=="PG"){
          this.showPOD=false;
          this.showPG=true;
          this.payOnDelivery=false;  
        }
        
      }
      else{

      }     
    },(err)=>{
      this.showLoader=false;
      this.outOfStock=false;
       console.log(err);
     });
  }
  updateCount(item,flag){
    var cnt=parseInt(item.count);
    if(item.count>=5 && flag=='inc'){
      return;
    }
   if(flag=="delete" || (item.count==1 && flag=="dsc")){
    this.showLoader=true;
    var itemRmvParam={
      productId:item.productid,
      userId:JSON.parse(sessionStorage.getItem('buyerInfo')).buyerid,
    }
    this.apiService.removeCartItem(name, itemRmvParam).subscribe((res: any) => {
      this.showLoader=false;
      if(res.code=="200"){
        this.ngOnInit();
      }
    },(err)=>{
      this.showLoader=false;
       console.log(err);
     });
    }
    else{
      this.showLoader=true;
      var itemParam={
        productId:item.productid,
        userId:JSON.parse(sessionStorage.getItem('buyerInfo')).buyerid,
        count:flag=="inc"?(cnt+1):(cnt-1)
       }
      this.apiService.updateCartItem(name, itemParam).subscribe((res: any) => {
        this.showLoader=false;
        if(res.code=="200"){
          this.ngOnInit();
        }
      },(err)=>{
        this.showLoader=false;
         console.log(err);
       });
    }
  }
  paymentMode(mode){
    this.onlinePay=false;
    this.payOnDelivery=true;
    if(mode=="ONP"){
      this.onlinePay=true;
      this.payOnDelivery=false;
      if(JSON.parse(sessionStorage.getItem('buyerInfo')) && !JSON.parse(sessionStorage.getItem('buyerInfo')).emailId){
        const dialogRef = this.dialog.open(BuyerinfoComponent, {
          width: '250px',
          data: {name: "", animal: ""}
        });
      }
      else this.orderCall();
    }
  }
  orderCall(){
  var pmt="";
  if(this.onlinePay)
  {
    pmt="PG";
  }
  else 
  { 
    pmt="POD";
  }
    
    this.showLoader=true; 
    var offerparam={buyerId:JSON.parse(sessionStorage.getItem('buyerInfo')).buyerid,sellerId:JSON.parse(sessionStorage.getItem("sellerDetail2")).sellerid,deliveryAddressId:JSON.parse(sessionStorage.getItem('selectedAddress')).addressid,paymentMode:pmt};
    this.apiService.getGenerateOrder(name, offerparam).subscribe((res: any) => {
      this.showLoader=false;
      if(res.code=="200"){
        this.generatedOrder=res.data
        sessionStorage.setItem("orderDetail",JSON.stringify(res.data));
        if(pmt=='POD')this.orderStatus(res.data,pmt);
        this.cashFree();
      }
      else{
        var msg="";
        if(res.message)msg=res.message+" or Click on Icon to download App."
        else{msg="Click on Icon to download App."}
        const dialogRef = this.dialog.open(MessageComponent, {
          width: '400px',
          data: {message: msg, type: "error",finalCart:"error"}
        });
      }
    },(err)=>{
      this.showLoader=false;
       console.log(err);
     });
  
}
orderStatus(response,pmt){
  var offerparam={orderId:response.orderId,paymentMode:pmt};
  this.showLoader=true;
  this.apiService.getOrderStatus(name, offerparam).subscribe((res: any) => {
    this.showLoader=false;
    if(res.code=="200"){      
      const dialogRef = this.dialog.open(MessageComponent, {
        width: '400px',
        data: {message: "Your Order is Placed Successfully, To Track and manage your Orders, Download ByBuy App", type: "success",finalCart:'finalCart'}
      });
    }
    else{
      const dialogRef = this.dialog.open(MessageComponent, {
        width: '400px',
        data: {message: res.data.message, type: "error",finalCart:'error'}
      });
    }
  },(err)=>{
    this.showLoader=false;
     console.log(err);
   });
}
cashFree(){
  if(!(JSON.parse(sessionStorage.getItem('orderDetail')).pgDetails) && !JSON.parse(sessionStorage.getItem('orderDetail')).pgDetails.pgKey){
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '400px',
      data: {message: "Online paymnet is not available.", type: "error",finalCart:'error'}
    });
    return;
  }
  var postData = {
		"appId" : JSON.parse(sessionStorage.getItem('orderDetail')).pgDetails.pgKey,
		"orderId" : this.generatedOrder.orderId,
		"orderAmount" : this.generatedOrder.totalSellingPrice,
		"orderCurrency" : "INR",
		"orderNote" : 'test',
		'customerName' :this.generatedOrder.deliveryAddress.name,
		"customerEmail" : JSON.parse(sessionStorage.getItem('buyerInfo')).emailId,
		"customerPhone" : JSON.parse(sessionStorage.getItem('buyerInfo')).mobileNo,
		"returnUrl" : window.location.origin+'/cashfreeResult',
    "notifyUrl" : window.location.origin+'/cashfreeResult',
    "signature":''
	},
	secretKey = JSON.parse(sessionStorage.getItem('orderDetail')).pgDetails.pgSecret,
	sortedkeys = Object.keys(postData),
	signatureData = "";
	sortedkeys.sort();
	for (var i = 0; i < sortedkeys.length; i++) {
    var k = sortedkeys[i];
    if(k!='signature')signatureData += k + postData[k];
  } 
   const signature2 = CryptoJS.HmacSHA256(signatureData, secretKey);
   const signatureBase=CryptoJS.enc.Base64.stringify(signature2);
	postData.signature = signatureBase;
  this.postData=postData;
}
}
