import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class DataProviderService {

  constructor(private http: HttpClient) { }
  public getHandler(handlerUrl: string, options: any) {
	  var paramsData = {
	  headers:new HttpHeaders().set('Authorization',JSON.parse(sessionStorage.getItem('sellerDetail')).authToken),
	  params:options
		}
    return this.http.get(handlerUrl, paramsData);
	}
  public postHandler(handlerUrl: string, body: any,flag:boolean) {
	  var options={};
	  if(flag){
		options={
			headers:new HttpHeaders().set('Authorization',JSON.parse(sessionStorage.getItem('sellerDetail')).authToken),
		  };
	  }
		return this.http.post(handlerUrl, body, options);
	}
   public putHandler(handlerUrl: string, body: any,flag:boolean) {
		var options={};
		if(flag){
		  options={
			  headers:new HttpHeaders().set('Authorization',JSON.parse(sessionStorage.getItem('sellerDetail')).authToken),
			};
		}
		  return this.http.put(handlerUrl, body, options);
	}
}
