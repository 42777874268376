import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {DataProviderService} from './data-provider.service'
 
@Injectable({
  providedIn: 'root'
})
export class ApiService {
   //15.207.132.39:8089 https://tohands.in/
  // baseUrl="http://15.207.132.39:8089"
   baseUrl="https://tohands.in"
  constructor(private dataProviderService:DataProviderService) { }

  getproductData(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
   var url=this.baseUrl+"/product/seller/getallproducts";
      return this.dataProviderService.getHandler(url, requestParams).pipe(map((data: any) => data));
  }
  getSearchedproductData(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
    var url=this.baseUrl+"/product/search";
   
       return this.dataProviderService.getHandler(url, requestParams).pipe(map((data: any) => data));
     
    
   }
  getShopDetail(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
    var url=this.baseUrl+"/seller/getInfo";
       return this.dataProviderService.getHandler(url, requestParams).pipe(map((data: any) => data));
     
   }
   getSubcription(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
    var url=this.baseUrl+"/seller/isSubscribed";
       return this.dataProviderService.getHandler(url, requestParams).pipe(map((data: any) => data));
     
   }
   
   getProDetail(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
    var url=this.baseUrl+"/product/";
    url=url+requestParams.prodId
       return this.dataProviderService.getHandler(url, requestParams).pipe(map((data: any) => data));
     
   }
   
   getOfferDetail(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/offer/getAllByLatLongAndCategory";
         return this.dataProviderService.getHandler(url, requestParams).pipe(map((data: any) => data));
       
     }
   getSimilarProd(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/product/getSimilarProducts";
         return this.dataProviderService.getHandler(url, requestParams).pipe(map((data: any) => data));
       
     }
   getLogin(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/buyer/register/login?username="+requestParams.username+(requestParams.otp?"&otp="+requestParams.otp:"");
         return this.dataProviderService.postHandler(url, requestParams,false).pipe(map((data: any) => data));
     }
   getBuyerinfo(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/buyer/getInfo";
         return this.dataProviderService.getHandler(url, requestParams).pipe(map((data: any) => data));
     }
   saveSubscribe(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/seller/subscribe?buyerId="+requestParams.buyerId+"&sellerId="+requestParams.sellerId;
         return this.dataProviderService.postHandler(url, requestParams,true).pipe(map((data: any) => data));
     }
   saveUnSubscribe(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/seller/unsubscribe?buyerId="+requestParams.buyerId+"&sellerId="+requestParams.sellerId;
         return this.dataProviderService.postHandler(url, requestParams,true).pipe(map((data: any) => data));
     }
   getCategories(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/seller/category";
         return this.dataProviderService.getHandler(url, requestParams).pipe(map((data: any) => data));
     }
   getBuyerInfo(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/buyer/getInfo";
         return this.dataProviderService.getHandler(url, requestParams).pipe(map((data: any) => data));
     }
   updateBuyerInfo(name: string, body: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/buyer/update";
         return this.dataProviderService.putHandler(url, body,true).pipe(map((data: any) => data));
     }
   getStoreOffer(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/offer/list";
         return this.dataProviderService.getHandler(url, requestParams).pipe(map((data: any) => data));
     }
   getFinalItem(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/cart/getCartBySeller/"+requestParams.buyerId+"/"+requestParams.sellerId+"/"+requestParams.deliveryAddressId;
         return this.dataProviderService.getHandler(url, {}).pipe(map((data: any) => data));
   }
   saveAddress(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/buyer/address/add";
         return this.dataProviderService.postHandler(url, requestParams,true).pipe(map((data: any) => data));
     }
   saveCartItem(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/cart/product/add?productId="+requestParams.productId+"&userId="+requestParams.userId+"&count="+requestParams.count;
         return this.dataProviderService.postHandler(url, {},true).pipe(map((data: any) => data));
     }
   updateCartItem(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/cart/product/updateCount?productId="+requestParams.productId+"&userId="+requestParams.userId+"&count="+requestParams.count;
         return this.dataProviderService.postHandler(url, {},true).pipe(map((data: any) => data));
     }
   removeCartItem(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/cart/removeFromCart?productId="+requestParams.productId+"&userId="+requestParams.userId;
         return this.dataProviderService.putHandler(url, {},true).pipe(map((data: any) => data));
     }
   getGenerateOrder(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/order/generateOrder/"+requestParams.buyerId+"/"+requestParams.sellerId+"/"+requestParams.deliveryAddressId+"/"+requestParams.paymentMode;
         return this.dataProviderService.getHandler(url, {}).pipe(map((data: any) => data));
     }
   getOrderStatus(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/order/"+requestParams.orderId+"/transactionStatus/"+requestParams.paymentMode;
     var body={}
      if(requestParams.cashfree){
         body={cashFreeResponseModel:requestParams.cashfree}
      }
         return this.dataProviderService.putHandler(url, body,true).pipe(map((data: any) => data));
     }
   getCashOrderStatus(name: string, requestParams: { [property: string]: any }): Observable<any[]> {
      var url=this.baseUrl+"/order/status/"+requestParams.orderId;
         return this.dataProviderService.getHandler(url, {}).pipe(map((data: any) => data));
     }
}
