<div class="body-content outer-top-xs" id="target-eel">
	<div class='container'>
		<div class='row single-product'>
			<div class='col-xs-12 col-sm-12 col-md-12'>
            <div class="detail-block">
				<div class="row">                
			    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 padd">
				<div style="text-align: center;margin-bottom: 10px;">
					<app-image-auth name="details" *ngIf="path" src="{{path}}" alt="Loading Image"></app-image-auth>
                </div>
                <div style="margin-left: 15px;">
                    <owl-carousel *ngIf="productDetail" [options]="carouselOptions"  [carouselClasses]="['owl-theme', 'row', 'sliding']">
                        <div class="item"  *ngFor="let image of productDetail.images.images;" >
						  <app-image-auth (click)="imagePath(image)" name="details" *ngIf="image" src="{{image}}" alt="Loading Image"></app-image-auth>
                        </div>
                      </owl-carousel>
                </div>
                    
                </div><!-- /.gallery-holder -->        			
					<div class='col-sm-12 col-md-8 col-lg-8 product-info-block'>
						<div class="product-info">
							<h1 class="name" *ngIf="productDetail && productDetail.productname">{{productDetail.productname}}</h1>
							<div class="stock-container info-container m-t-10">
								<div class="row">
                                <div class="col-lg-12">
									<div class="pull-left">
										<div class="stock-box">
											<span class="label">Availability :</span>
										</div>	
									</div>
									<div class="pull-left">
										<div class="stock-box">
											<span class="value" *ngIf="!outOfStock">In Stock</span>
											<span class="value" *ngIf="outOfStock">Out Of Stock</span>
										</div>	
									</div>
                                    </div>
								</div><!-- /.row -->	
							</div><!-- /.stock-container -->

							<div class="description-container m-t-20">
								<p style="text-align: justify;" *ngIf="productDetail && productDetail.description">{{productDetail.description}}</p>
							</div><!-- /.description-container -->
							<div class="description-container m-t-20" *ngIf="offer">
								<p style="font-weight: bold;" *ngIf="storeServices.storeDetails">Offer From {{storeServices.storeDetails.data.shopName}}</p>
							</div>
							<div style="margin-left: 15px;" *ngIf="offer">
							<owl-carousel [options]="carouselOptions"  [carouselClasses]="['owl-theme', 'row', 'sliding']">
								<div class="item"  *ngFor="let ofr of offer;" >
								 <span style="font-weight: bold;">{{ofr.offertext}}</span>
								</div>
							  </owl-carousel>
							</div>
							<div class="price-container info-container m-t-30">
								<div class="row">
									<div class="col-sm-6 col-xs-6">
										<div class="price-box" *ngIf="productDetail">
											<span class="price" >{{productDetail.sellingprice | currency:'INR'}}</span>
											<span class="price-strike">{{productDetail.mrp | currency:'INR'}}</span>
										</div>
									</div>

								</div><!-- /.row -->
							</div><!-- /.price-container -->

							<div class="quantity-container info-container">
								<div class="row">
									
									<div class="qty">
										<span class="label">Qty :</span>
									</div>
									
									<div class="qty-count">
										<div class="cart-quantity">
											<div class="quant-input">
								                <div class="arrows">
								                  <div class="arrow plus gradient" (click)="quantity(true)"><span class="ir"><i class="icon fa fa-sort-asc"></i></span></div>
								                  <div class="arrow minus gradient" (click)="quantity(false)"><span class="ir"><i class="icon fa fa-sort-desc"></i></span></div>
								                </div>
								                <input type="text" disabled value="{{quantityVal}}">
							              </div>
							            </div>
									</div>

									<div  class="add-btn" (click)="addProductCart()" *ngIf="!outOfStock">
										<a  class="btn btn-primary" style="color: white!important;"><i class="fa fa-shopping-cart inner-right-vs"></i> ADD TO CART</a>
									</div>
									<div  class="add-btn" *ngIf="outOfStock">
										<a  class="btn btn-primary" style="color: white!important;"><i class="fa fa-shopping-cart inner-right-vs"></i> Out Of Stock</a>
									</div>

									
								</div><!-- /.row -->
							</div>
						</div><!-- /.product-info -->
					</div><!-- /.col-sm-7 -->
                </div><!-- /.row -->
				<!-- Similar item -->
				<div class='col-sm-12 col-md-12 col-lg-12' style="text-align: center;">
					<h3>Similar Item</h3>
				</div>
					<div  infiniteScroll [infiniteScrollDistance]="1" (scrolled)="onScrollDown()" [fromRoot]="true" class="scroll-tabs outer-top-vs row">	
						<div style="text-align: center;">			               

							   <div class="itemview" *ngFor="let prod of productList">
								 <a routerLink="/detail" >
								   <div  class="product"  (click)="setProdDetail(prod);">
									 <div class="product-image">
									   <div class="image">
										<div class="sale-offer-tag" *ngIf="prod.discountPercent>0">{{prod.discountPercent | number:'1.0-0'}}% Off</div>
										 <app-image-auth src="{{prod.images.thumbnailImage}}" alt="Loading Image"></app-image-auth>
									</div>
									 </div>
									 <div class="product-info text-left">
									   <h3 class="name nowrap">{{prod.productname}}</h3>
									   <div class="product-price"> <span class="price">{{prod.sellingprice | currency:'INR'}}</span> 
										 <span class="price-before-discount" *ngIf="prod.sellingprice<prod.mrp">{{prod.mrp | currency:'INR'}}</span>
										 <!-- <span style="float: right;"><button data-toggle="tooltip" class="btn btn-primary icon" type="button" title="Add Cart"> <i class="fa fa-shopping-cart"></i> </button></span>  -->
									   </div>
									 </div>                      
								   </div>
								   </a>
							   </div>     
							</div>                     
					   
					 </div>
				   
            </div>
				
			</div>
		</div>
</div>
</div>
<app-loader [show]="showLoader"></app-loader>
