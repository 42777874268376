import { Component, OnInit } from '@angular/core';
import { CommonServicesService } from '../common-services.service';
import { ApiService } from '../services/api.service';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { MatDialog } from '@angular/material';
import { PopupComponent } from '../popup/popup.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageComponent } from '../message/message.component';
import { AppConfigService } from '../app-config.service';
import { StoreService } from '../store.service';
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
  carouselOptions = {
    margin: 25,
    nav: true,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 4,
        nav: true
      },
      600: {
        items: 4,
        nav: true
      },
      1000: {
        items: 4,
        nav: true,
        loop: false
      },
      1500: {
        items: 4,
        nav: true,
        loop: false
      }
    }
  }

  images = [
    {
      text: "Everfresh Flowers",
      image: "assets/images/products/p2.jpg"
    },
    {
      text: "Festive Deer",
      image: "assets/images/products/p1.jpg"
    },
    {
      text: "Morning Greens",
      image: "assets/images/products/p3.jpg"
    },
    {
      text: "Bunch of Love",
      image: "assets/images/products/p3.jpg"
    },
    {
      text: "Blue Clear",
      image: "assets/images/products/p5.jpg"
    },
    {
      text: "Evening Clouds",
      image: "assets/images/products/p1.jpg"
    },
    {
      text: "Fontains in Shadows",
      image: "assets/images/products/p2.jpg"
    },
    {
      text: "Kites in the Sky",
      image: "assets/images/products/p3.jpg"
    },
    {
      text: "Sun Streak",
      image: "assets/images/products/p3.jpg"
    }
  ]
  params={
    "productId":sessionStorage.getItem('prodId'),
    "latitude":JSON.parse(sessionStorage.getItem('sellerDetail2')).latitude,
    "categoryid":"",
    "longitude":JSON.parse(sessionStorage.getItem('sellerDetail2')).longitude,
    "page":0,
    "size":10,
    "isProductActive":true
  }
  path:any;
  showLoader: boolean;
  productDetail: any;
  subscription: Subscription;
  offer: any;
  productList: any;
  targetElement: any;
  quantityVal: any=1;
  stopCalling: boolean;
  outOfStock: boolean;

  constructor(public storeServices:StoreService,private apiService:ApiService,public commonServices:CommonServicesService,public dialog: MatDialog,private router: Router) { }

  ngOnInit() {
    this.stopCalling=false;
    this.outOfStock=false;
    this.quantityVal=1;
    this.targetElement = document.querySelector('#target-eel');
    this.callDetailProduct();
    this.callStoreOffer();
    this.callProductApi(false);
    this.subscription = this.commonServices.getAddCartCall().subscribe(x => { 
      this.addProductCart(); 
    });
  }
  imagePath(path){
    this.path=path;
  }
  setProdDetail(proddetail){
    sessionStorage.setItem('prodId', proddetail.productid);
    location.reload();
   }
  callDetailProduct(){    
    this.showLoader=true;
    this.outOfStock=false;
      this.apiService.getProDetail(name, {prodId:sessionStorage.getItem('prodId')}).subscribe((res: any) => {
        this.showLoader=false;
        if(res.code=="200"){
        this.productDetail=res.data;        
        this.productDetail.images.images.unshift(res.data.images.thumbnailImage);
        if(res.data.quantity<=0)this.outOfStock=true;

        this.path=res.data.images.thumbnailImage
        }
      },(err)=>{
        this.showLoader=false;
         console.log(err);
       });
  }
  callStoreOffer(){    
    this.showLoader=true;
    var offerparam={"categoryid":6,
                    "sellerId":73}
      this.apiService.getOfferDetail(name, offerparam).subscribe((res: any) => {
        this.showLoader=false;
        this.offer=res.data;
      },(err)=>{
        this.showLoader=false;
         console.log(err);
       });
  }
  onScrollDown() {
    if(this.params.page==0)this.params.page=1;
    this.callProductApi(true);

   }
   callProductApi(callonScroll){
    if(this.stopCalling) return;
    this.showLoader=true;
    this.params.categoryid=""; 
    disableBodyScroll(this.targetElement);
    this.apiService.getSimilarProd(name, this.params).subscribe((res: any) => {
      this.showLoader=false;
      enableBodyScroll(this.targetElement);
      if(this.params.page==0)this.params.page=1;
      if(callonScroll)
      {  this.params.page=this.params.page+1;
        this.productList=this.productList.concat(res.data.content);
      }
      else {
        this.productList=res.data.content;
      }
      this.stopCalling=false;
        if(res.data.content.length==0){
          this.stopCalling=true;
        }
     },(err)=>{
      this.showLoader=false;
      enableBodyScroll(this.targetElement);
       console.log(err);
     }); 
   }
   quantity(flag){
    if(flag){
      if(this.quantityVal>=5)return;
      this.quantityVal++;
     }
     else{
      this.quantityVal--;
     }
     if(this.quantityVal<=0){
      this.quantityVal=1;
     }
   }
   addProductCart(){
    if(!sessionStorage.getItem('buyerInfo')){
      this.commonServices.otp=false;
      this.commonServices.callFrom="addProduct";
      const dialogRef = this.dialog.open(PopupComponent, {
        width: '250px',
        data: {name: "", animal: ""}
      });
     }
     else {
       var params={
        productId:sessionStorage.getItem('prodId'),
        userId:JSON.parse(sessionStorage.getItem('buyerInfo')).buyerid,
        count:this.quantityVal
       }
      this.showLoader=true;
      this.apiService.saveCartItem(name, params).subscribe((res: any) => {
        this.showLoader=false;
        if(res.code=="200"){
          const dialogRef = this.dialog.open(MessageComponent, {
            width: '250px',
            data: {message: "Added to cart", type: "success",finalCart:'CartAdded'}
          });
        }
        else{
          const dialogRef = this.dialog.open(MessageComponent, {
            width: '400px',
            data: {message: "Some Error Occured", type: "error"}
          });
        }
      },(err)=>{
        this.commonServices.otp=false;
        this.showLoader=false;
         console.log(err);
       });
     }
   }
}
