import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MessageComponent } from '../message/message.component';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-cashfree-result',
  templateUrl: './cashfree-result.component.html',
  styleUrls: ['./cashfree-result.component.scss']
})
export class CashfreeResultComponent implements OnInit {
  showLoader: boolean;

  constructor(private apiService:ApiService,public dialog: MatDialog) { }

  ngOnInit() {
    var orderparam={
      "orderId":JSON.parse(sessionStorage.getItem('orderDetail')).orderId
    }
    this.showLoader=true;
    this.apiService.getCashOrderStatus(name, orderparam).subscribe((res: any) => {
      this.showLoader=false;
      if(res.code=="200"){
        this.orderStatus(res.data,res.data.transactionDetails.indexOf('SUCCESS')>=0?'PG_SUCCESS':'PG_FAILURE');
      }
      else{
        this.orderStatus({transactionDetails:""},'PG_FAILURE');
      }     
    },(err)=>{
      this.showLoader=false;
       console.log(err);
     });
  }
  orderStatus(response,pmt){
    var offerparam={orderId:JSON.parse(sessionStorage.getItem('orderDetail')).orderId,paymentMode:pmt,cashfree:response.transactionDetails};
    this.showLoader=true;
    this.apiService.getOrderStatus(name, offerparam).subscribe((res: any) => {
      this.showLoader=false;
      if(res.code=="200"){  
        if(pmt=="PG_SUCCESS") 
        {
          const dialogRef = this.dialog.open(MessageComponent, {
            width: '400px',
            data: {message: "Your Order is Placed Successfully, To Track and mange your Orders, Download ByBuy App", type: "success",finalCart:'finalCart'}
          });
        }  
        else{
          const dialogRef = this.dialog.open(MessageComponent, {
            width: '400px',
            data: {message: "Your Transaction Got Failed, Please try Again..", type: "error",finalCart:'error'}
          });
        } 
        
      }
      else{
        const dialogRef = this.dialog.open(MessageComponent, {
          width: '400px',
          data: {message: res.message, type: "error",finalCart:'error'}
        });
      }
    },(err)=>{
      this.showLoader=false;
       console.log(err);
     });
  }

}
