import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CommonServicesService } from './common-services.service';
import { PopupComponent } from './popup/popup.component';
import {ApiService} from './services/api.service';
import { AppConfigService } from './app-config.service';
import { StoreService } from './store.service';
import { MessageComponent } from './message/message.component';
declare let $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  searchTextChanged = new Subject<string>();
  requestParams:any;
  shopDetails: any;
  showLoader: boolean;
  debounce:any;
  constructor(public storeServices:StoreService,private apiService:ApiService,public commonServices:CommonServicesService,private router: Router,public dialog: MatDialog){}
  title = 'bybuy';
  searchData:any;
  ngOnInit(){
    this.debounce = 0;
      
  }
  searchProduct(vl){
  clearTimeout(this.debounce);
  var local=this;
  this.debounce = setTimeout(function(){
    local.commonServices.callSearch(vl.target.value);
  },500);
}
flush(){
  if( (<HTMLInputElement>document.getElementById('search')).value){
    (<HTMLInputElement>document.getElementById('search')).value="";
  }
}
checkout(){
  if(!sessionStorage.getItem('buyerInfo')){
    this.commonServices.otp=false;
    this.commonServices.callFrom="checkout";
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '250px',
      data: {name: "", animal: ""}
    });
   }
   else {
    this.router.navigate(['/checkout']);
   }
}
}
