import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,APP_INITIALIZER } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DetailComponent } from './detail/detail.component';
import { AddressComponent } from './address/address.component';
import { HomeComponent } from './home/home.component';
import { OwlModule } from 'ngx-owl-carousel';
import { CheckoutComponent } from './checkout/checkout.component';
import { FormsModule ,ReactiveFormsModule } from '@angular/forms';
import { RestrictInputDirective } from './restrict-input.directive';
import { InfiniteScrollModule} from 'ngx-infinite-scroll';
import { ApiService } from './services/api.service';
import {PopupComponent} from './popup/popup.component'
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatCommonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatLineModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatOptionModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatPseudoCheckboxModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import { LoaderComponent } from './loader/loader.component';
import { ImageAuthComponent } from './image-auth/image-auth.component';
import { AuthImagePipe } from './auth-image.pipe';
import { FinalcartComponent } from './finalcart/finalcart.component';
import { MessageComponent } from './message/message.component';
import { TncComponent } from './tnc/tnc.component';
import { EncryptedComponent } from './encrypted/encrypted.component'
import { AppConfigService } from './app-config.service';
import { StoreService } from './store.service';
import { CashfreeResultComponent } from './cashfree-result/cashfree-result.component';
import { BuyerinfoComponent } from './buyerinfo/buyerinfo.component';
export function  appInit(appConfigService: AppConfigService) {
  return  () =>  appConfigService.getSomeData();
}
@NgModule({
  declarations: [
    AppComponent,
    DetailComponent,
    AddressComponent,
    HomeComponent,
    CheckoutComponent,RestrictInputDirective, LoaderComponent, ImageAuthComponent, AuthImagePipe, PopupComponent,MessageComponent, FinalcartComponent, MessageComponent, TncComponent, EncryptedComponent, CashfreeResultComponent, BuyerinfoComponent
  ],
  imports: [FormsModule,ReactiveFormsModule,BrowserAnimationsModule,InfiniteScrollModule,
    BrowserModule,
    AppRoutingModule,HttpClientModule,OwlModule,MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatCommonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatLineModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatOptionModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatPseudoCheckboxModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  ],
  entryComponents: [ PopupComponent,MessageComponent,BuyerinfoComponent],
  providers: [ApiService,{
    provide: APP_INITIALIZER,
    useFactory: appInit,
    multi: true,
    deps: [AppConfigService]
  },AppConfigService,StoreService],
  bootstrap: [AppComponent]
})
export class AppModule { }
