import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServicesService } from '../common-services.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  showLoader: boolean;
  addressList: any;
  constructor(private apiService:ApiService,public commonServices:CommonServicesService,private router: Router) { }

  ngOnInit() {
    var requestParams={
      "id":JSON.parse(sessionStorage.getItem('buyerInfo')).buyerid,
      "mobileno":JSON.parse(sessionStorage.getItem('buyerInfo')).mobileNo
    }
    this.showLoader=true;
    this.apiService.getBuyerInfo(name, requestParams).subscribe((res: any) => { 
      this.showLoader=false;
      if(res.code=="200" && res.data.buyerAddress && res.data.buyerAddress.length>0){
        for (let index = 0; index <  res.data.buyerAddress.length; index++) {
          if(index==0) res.data.buyerAddress[index].checked=true;
          else res.data.buyerAddress[index].checked=false;
        }
      this.addressList=res.data.buyerAddress;
      sessionStorage.setItem("selectedAddress",JSON.stringify(res.data.buyerAddress[0]));
      }
      else {
        this.router.navigate(['/address']);
      }      
      },(err)=>{
        this.showLoader=false;
         console.log(err);
       });
  }
  selectAddress(add){
    for (let index = 0; index <  this.addressList.length; index++) {
      if(add.addressid==this.addressList[index].addressid) 
      {
        this.addressList[index].checked=true;
        sessionStorage.setItem("selectedAddress",JSON.stringify(this.addressList[index]));
      }
      else this.addressList[index].checked=false;
    }
  }
}
