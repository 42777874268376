<header class="header-style-1" style="position: sticky;top: 0px;z-index: 5;">
  <div class="main-header">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-xs-2 col-sm-2" (click)="flush()" style="top: 10px;"> 
           <div routerLink="/home" ><img class="wid" src="assets/images/home.png"/></div>
          </div>
        <div class="col-lg-6 col-md-6 col-sm-8 col-xs-8" style="top: 5px;"> 
            <form class="example" style="padding: 5px" >
              <input type="text" id="search" [(ngModel)]="searchData"  (keyup)="searchProduct($event)" placeholder="Search Product here..." name="search"/>
              <button type="submit" style="color: black;"><i class="fa fa-search"></i></button>
            </form>
         </div>
        <div class="col-lg-3 col-md-3 col-sm-2 col-xs-2" style="top: 5px;">           
          <div style="color: white !important;float: right;padding: 5px;"  (click)="checkout()">
            <i style="font-size: 30px;" class="fa fa-shopping-cart"></i>
            
          </div>
          </div>
       
      </div>      
    </div>    
  </div>
</header>
<!-- <div class="container">
  <div class="col-xs-12 col-sm-12 col-md-12 logo-holder" style="margin-top: 10px;text-align: right;"> 
      <button mat-button routerLink="/home"  style="border-style: revert;">Home</button>
  </div>
</div> -->

<router-outlet ></router-outlet>
<!-- ============================================================= FOOTER ============================================================= -->
<footer id="footer" class="footer color-bg">
  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3">
          <div class="module-heading">
            <h4 class="module-title">Description</h4>
          </div>
        
          <!-- /.module-heading -->          
          <div class="module-body">
            <ul class="toggle-footer">
              <li class="media">
                <div class="pull-left"> <span class="icon fa-stack fa-lg"> <i class="fa fa-align-justify fa-stack-1x fa-inverse"></i> </span> </div>
                <div class="media-body">
                  <p *ngIf="storeServices.storeDetails" style="margin-top: 5px;">{{storeServices.storeDetails.data.shopDescription}}</p>
                </div>
              </li>
             
            </ul>
          
          </div>
          <!-- /.module-body --> 
        </div>
        <!-- /.col -->
        
        <div class="col-xs-12 col-sm-6 col-md-3">
          <div class="module-heading">
            <h4 class="module-title">Location</h4>
          </div>
          <!-- /.module-heading -->
          <div class="module-body">
            <ul class="toggle-footer">
              <li class="media">
                <div class="pull-left"> <span class="icon fa-stack fa-lg"> <i class="fa fa-map-marker fa-stack-1x fa-inverse"></i> </span> </div>
                <div class="media-body">
                  <p *ngIf="storeServices.storeDetails" style="margin-top: 5px;">{{storeServices.storeDetails.data.address}}</p>
                </div>
              </li>
            </ul>
          </div>
          <!-- /.module-body --> 
        </div>
        <!-- /.col -->
        
        <div class="col-xs-12 col-sm-6 col-md-3">
          <div class="module-heading">
            <h4 class="module-title">Contact Us</h4>
          </div>
          <!-- /.module-heading -->
          
          <div class="module-body">
            <ul class="toggle-footer">
              <li class="media">
                <div class="pull-left"> <span class="icon fa-stack fa-lg"> <i class="fa fa-mobile fa-stack-1x fa-inverse"></i> </span> </div>
                <div class="media-body">
                  <p *ngIf="storeServices.storeDetails" style="margin-top: 5px;">{{storeServices.storeDetails.data.mobileNo}}</p>
                </div>
              </li>
              <li class="media">
                <div class="pull-left"> <span class="icon fa-stack fa-lg"> <i class="fa fa-envelope-square fa-stack-1x fa-inverse"></i> </span> </div>
                <div class="media-body">
                  <p *ngIf="storeServices.storeDetails" style="margin-top: 5px;">{{storeServices.storeDetails.data.emailId}}</p>
                </div>
              </li>
            </ul>
          </div>
          <!-- /.module-body --> 
        </div>
        <!-- /.col -->
        
        <div class="col-xs-12 col-sm-6 col-md-3">
          <div class="module-heading">
            <h4 class="module-title">Terms & Condition</h4>
          </div>
          <!-- /.module-heading -->
          
          <div class="module-body">
            <ul class="toggle-footer">
              <li class="media">
                <div class="pull-left"> <span class="icon fa-stack fa-lg"> <i class="fa fa-anchor fa-stack-1x fa-inverse"></i> </span> </div>
                <div class="media-body"> <span><a routerLink="/tnc" style="color: #abafb1 !important;">Click Here</a></span> </div>
              </li>
            </ul>
          </div>
          <!-- /.module-body --> 
        </div>
      </div>
     
    </div>
    <div style="text-align: center;">
      <a style="color: white !important;" href="https://play.google.com/store/apps/details?id=in.bybuy.www.seller">Powered by ByBuy Seller App.</a> 
    </div>
  </div>
</footer>

