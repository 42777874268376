<div class="body-content outer-top-xs" id="target-eel">
	<div class='container'>
		<div class='row single-product'>
			<div class='col-xs-12 col-sm-12 col-md-12'>
            <div class="detail-block">	
                <span style="font-size: 16px;font-weight: 700;">Terms & Condition</span>			   
            </div>				
			</div>
        </div>
        <div class='row single-product'>
			<div class='col-xs-12 col-sm-12 col-md-12'>
            <div class="detail-block" *ngIf="storeServices.storeDetails" style="text-align: justify;">	
                {{storeServices.storeDetails.data.tnc}}			   
            </div>				
			</div>
		</div>
</div>
</div>
