import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { CommonServicesService } from '../common-services.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-buyerinfo',
  templateUrl: './buyerinfo.component.html',
  styleUrls: ['./buyerinfo.component.scss']
})
export class BuyerinfoComponent implements OnInit {
  name:any;
  subscription: Subscription;
  email:any;
  registerForm: FormGroup;
  showLoader:boolean;
  submitted = false;
  constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<BuyerinfoComponent>,private apiService:ApiService,public commonServices:CommonServicesService) { }

  ngOnInit() {
    this.submitted = false;
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
  });
   
  }
  get f() { return this.registerForm.controls; }
  onNoClick(): void {
    this.dialogRef.close();
  }
  callForSubmit(){
    this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
    var offerparam={
      "buyerId":JSON.parse(sessionStorage.getItem('buyerInfo')).buyerid,
      "name": this.name,
      "profileImage":"",
      "emailId": this.email,
      "mobileVerified": false,
      "emailVerified": false,
      "active":true,
      "socialLogin":false
    }    
    this.showLoader=true;
    this.apiService.updateBuyerInfo(name, offerparam).subscribe((res: any) => {
      this.showLoader=false;
      this.onNoClick();
      if(res.code=="200" && res.data && res.data.buyerid){
        sessionStorage.setItem("buyerInfo",JSON.stringify(res.data));
        this.commonServices.subject6.next(true);
      }
    },(err)=>{
      this.showLoader=false;
       console.log(err);
     });
  }

}
