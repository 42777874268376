import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CommonServicesService {
  storeDetails:any;
  storeServices:any=[];
  showImageLoader:boolean;
  page:any=-1;
  searchPage:any;
  size:any;
  params:any={};
  otp:boolean;
  callFrom:any;
  defaultUrl:"https://www.bybuy.in/";
  private subject = new Subject<any>();
  private subject2 = new Subject<any>();
  private subject3 = new Subject<any>();
  private subject4 = new Subject<any>();
  private subject5 = new Subject<any>();
  subject6 = new Subject<any>();
  prodId: any;
  loader: boolean;
  constructor(private apiService:ApiService,private router: Router) { 
  }

   
  callSearch(name) {
    this.subject.next(name);
   }
   callImage(flag) {
    this.subject2.next(flag);
   }
   getData(): Observable<any> {
    return this.subject.asObservable();
   }
   getload(): Observable<any> {
    return this.subject2.asObservable();
  }
  getOTPStatus(): Observable<any> {
    return this.subject3.asObservable();
  }
  getSubscription(): Observable<any> {
    return this.subject4.asObservable();
  }
  getAddCartCall(): Observable<any> {
    return this.subject5.asObservable();
  }
  getBuerinfoCall(): Observable<any> {
    return this.subject6.asObservable();
  }
  getLogin(det){    
    this.otp=false;
    this.loader=true;
    this.apiService.getLogin(name, det).subscribe((res: any) => {
      this.loader=false;
      this.otp=false;
      if(res.code=="200" && res.data.request_id){
        this.otp=true;
      }
      else if(res.code=="200" && res.data.buyerid){
        sessionStorage.setItem('buyerInfo',JSON.stringify(res.data));
        this.subject3.next(true);
        if(this.callFrom=="subsciption"){
          this.subject4.next(true);
        }
        else if(this.callFrom=="checkout"){
          this.router.navigate(['/checkout']);
        }
        else if(this.callFrom=="addProduct"){
          this.subject5.next(true);
        }
      }
      else if(res.code=="500"){
        this.subject3.next({"msg":res.message});
      }
    });
  }
  disableScroll() { 
    // Get the current page scroll position 
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop; 
    var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  
        // if any scroll is attempted, set this to the previous value 
        window.onscroll = function() { 
            window.scrollTo(scrollLeft, scrollTop); 
        }; 
} 
    enableScroll() { 
      window.onscroll = function() {}; 
    }
    
}
