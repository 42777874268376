<div mat-dialog-content>
   <label class="{{type}}" *ngIf="type=='success'" ><i class="fa fa-check-circle" aria-hidden="true"></i></label>
   <label class="{{type}}" *ngIf="type=='error'" > <i class="fa fa-exclamation-circle" aria-hidden="true"></i></label>
  </div>
  <div style="text-align: center;font-size: 20px;">
    {{message}} 
  </div>  
  <div mat-dialog-actions >
    <div style="float: right; width: 50%;outline-style: none;" *ngIf="callFrom=='finalCart' || callFrom=='error'">
     <a (click)="onNoClick()" href="https://play.google.com/store/apps/details?id=in.konnectme.www" target="_blank" title="Click here to download"> <img src="assets/images/playstore.png" alt="loading" style="width: 100%;"/></a>
    </div>
    <div style="width: 50%;text-align: right;">
      <button mat-button (click)="onNoClick()" style="border-style: revert;margin-right: 5px;">Continue</button>
    </div>  
    <div style="width: 50%;outline-style: none;float: right;" *ngIf="callFrom=='CartAdded'">
      <button mat-button (click)="chekout()" style="border-style: revert;background-color:#3F44D8;">Go To Cart</button>
     </div>
      
  </div>