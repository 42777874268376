import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DetailComponent} from './detail/detail.component';
import {AddressComponent} from './address/address.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {FinalcartComponent} from './finalcart/finalcart.component';
import {EncryptedComponent} from './encrypted/encrypted.component';
import {HomeComponent} from './home/home.component';
import {TncComponent} from './tnc/tnc.component'
import { CashfreeResultComponent } from './cashfree-result/cashfree-result.component';
const routes: Routes = [
{ path: 'home', component: HomeComponent},
{ path: 'detail', component: DetailComponent },
{ path: 'address', component: AddressComponent },
{ path: 'checkout', component: CheckoutComponent },
{ path: 'finalcart', component: FinalcartComponent },
{ path: 'tnc', component: TncComponent },
{ path: 'cashfreeResult', component: CashfreeResultComponent },
{ path: '**', component: EncryptedComponent },
{ path: '', component: EncryptedComponent }];


@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  
}
