<!-- <div class="hack">
  <div class="box">E</div>
</div> -->
<div class="body-content outer-top-vs" id="top-banner-and-menu">
    <div class="container">
      <div class="row"> 
        <!-- ============================================== SIDEBAR ============================================== -->
        <div class="col-xs-12 col-sm-12 col-md-3 sidebar"> 
          
          <!-- ================================== TOP NAVIGATION ================================== -->
          <div class="side-menu animate-dropdown outer-bottom-xs">
            <div  class="head" *ngIf="storeServices.storeDetails"> {{storeServices.storeDetails.data.shopName}}</div>
            <div class="yamm megamenu-horizontal">
              <app-image-auth name="storeImage" *ngIf="storeServices.storeDetails" src="{{storeServices.storeDetails.data.images.thumbnailImage}}" alt="Loading Image" ></app-image-auth>
            </div>
            <div style="display: inline-block;background-color: #fff;margin-top: 10px;width: 100%;" class="yamm megamenu-horizontal">
              <div class="col-xs-4 col-sm-4 col-md-4" style="text-align: center;">
                <span ><span *ngIf="storeServices.storeDetails" style="margin-left: 3px;font-weight: bold;">{{storeServices.storeDetails.data.totalSubscribers }} Subscribers</span></span>
              </div>
            <div class="col-xs-4 col-sm-4 col-md-4" style="text-align: center;">
              <span class="fa fa-star checked"><span *ngIf="storeServices.storeDetails" style="margin-left: 3px;">{{storeServices.storeDetails.data.rating | number:'1.1-1'}}</span></span>
            </div>
            <div  class="col-xs-4 col-sm-4 col-md-4" style="text-align: center;">
              <button data-toggle="tooltip" class="btn btn-primary icon"(click)="unSubscribe()"  type="button" *ngIf="subsFlag" style="margin-left: -25px;"> Subscribed</button>
              <button data-toggle="tooltip" class="btn btn-primary icon"  type="button" (click)="subscibeCheck()" *ngIf="!subsFlag" title="Click For Subscribe store" style="margin-left: -25px;"> Subscribe</button>
            </div>
          </div>  
          <div style="display: inline-block;background-color: #fff;margin-top: 10px;width: 100%;" class="yamm megamenu-horizontal">
          <owl-carousel [options]="SlideOptions" [items]="storeServices.storeServices" [carouselClasses]="['owl-theme', 'sliding']" >  
            <div class="item" *ngFor="let img of storeServices.storeServices">  
            <div style="align-content: center">  
            <span style="width:100%" >{{img}}</span>  
            </div>  
            </div>  
            </owl-carousel>  
            </div>
            <div style="width: 100%;" class="yamm megamenu-horizontal">
              <owl-carousel [options]="SlideOptions" [items]="storeOfferDiscPer" [carouselClasses]="['owl-theme', 'sliding']" >  
                <div class="item" *ngFor="let ofr of storeOfferDiscPer">  
                <div style="align-content: center">  
                <span style="width:100%;color: #ff7878;" >{{ofr.discountpercent}} % OFF {{ofr.categoryName}}</span>  
                </div>  
                </div>  
                </owl-carousel>  
            </div>
          </div>
        </div>
       <div class="col-xs-12 col-sm-12 col-md-9 homebanner-holder" > 
         <div id="product-tabs-slider" class="scroll-tabs outer-top-vs" style="min-height: 400px;">
          <div class="row" *ngIf="hideCategory==false">
            <div class="col-xs-12 col-sm-12 col-md-12">
            <mat-tab-group (selectedTabChange)="callCatProduct($event)">
              <mat-tab *ngFor="let cat of categoryData"  label="{{cat.name}}">
                <div id="carouselExampleControls"  class="carousel slide" data-ride="carousel">
                </div>
              </mat-tab>
            </mat-tab-group>
          </div> 
          </div>
            <div class="tab-content " infiniteScroll
            (scrolled)="onScrollDown()" [fromRoot]="true" *ngIf="productList && productList.length>0">               
                    <div class="itemview" *ngFor="let prod of productList">
                      <a routerLink="/detail" >
                        <div  class="product" (click)="setProdDetail(prod);">
                          <div class="product-image">
                            <div class="image">
                              <div class="sale-offer-tag" *ngIf="prod.discountPercent>0">{{prod.discountPercent | number:'1.0-0'}}% Off</div>
                              <app-image-auth src="{{prod.images.thumbnailImage}}" alt="Loading Image" ></app-image-auth>
                         </div>
                          </div>
                          <div class="product-info text-left">
                            <h3 class="name nowrap" title="{{prod.productname}}">{{prod.productname}}</h3>
                            <div class="product-price"> <span class="price">{{prod.sellingprice | currency:'INR'}}</span> 
                              <span class="price-before-discount" *ngIf="prod.sellingprice<prod.mrp">{{prod.mrp | currency:'INR'}}</span>
                            </div>
                          </div>                      
                        </div>
                        </a>
                    </div>                          
            </div>

            <div class="center-cls" *ngIf="productList && productList.length<=0" style="top: 200px;">
              <a style="font-size: 15 px;" >Product is not available</a> 
           </div>  
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loader [show]="showLoader"></app-loader>