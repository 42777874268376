<div class="body-content" >
    <div class="container addContainer" style="min-height: 450px;">
        <div class="row" style="margin-top: -20px;">
            <div class="col-xs-12 col-sm-12 col-md-12"> 
                <label style="font-size: 20px;">Add Address</label>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 ">
                 <input  
                    id="pac-input"
                    class="controls"
                    type="text"
                     style="height: 50px;;border-radius: 50px;" placeholder="Search Your Location Here..."
                  />
                <div id="map" class="map-view" ></div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 ">
                <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                             <i class="fa fa-map-marker "></i>
                            <span style="margin-right: -32x;margin-left: 5px;">{{flatNumber}} {{address}} {{toReach}}</span>
                    </div>
                    
                    </div>
                   
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                        <h4>Flat, House No, Building, Company, Apartment (Optional)</h4>
                    </div>
                    
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12" >
                            <input id="flatNumber" formControlName="flatNumber"  [(ngModel)]="flatNumber" class="input-field search-field"  />
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                        <h4>Area Colony (Optional)</h4>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                            <input id="areaColony" formControlName="address"  [(ngModel)]="address" class="input-field search-field"  />
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 ">
                        <h4>How To Reach (Optional)</h4>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                            <input id="toReach" formControlName="toReach"  [(ngModel)]="toReach" class="input-field search-field"  />
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                        <h4>Name</h4>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                            <input id="name" type="text" formControlName="name"  [(ngModel)]="name" class="input-field search-field"  />
                    </div>
                    <div class="error" *ngIf="f.name.errors && f.name.errors.required">Please enter name..</div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                        <h4>Mobile Number</h4>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                            <input type="text" id="number" formControlName="mobileNo"  [(ngModel)]="mobileNo" [restrict-input]="'numberOnly'" enableValidation="true"  min="10" max="10" class="input-field search-field" />
                    </div>
                    <div class="error" *ngIf="f.mobileNo.errors && f.mobileNo.errors.required">Please enter mobile number..</div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12" style="text-align: center;top: 20px;">
                            <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                    </div>
                </form>               
            </div>
        </div>
         
        
    </div>
</div>
<app-loader [show]="showLoader"></app-loader>