import { Component, OnInit } from '@angular/core';
import { FormBuilder,Validators,FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  mapType = 'satellite';
  map:any;
  address: string;
  mobileNo:number;
  formGroup: FormGroup;
  name:any;
  toReach:any;
  areaColony:any;
  flatNumber:any;
  lat: number;
  long: number;
  pincode:any;
  state:any;
  city:any;
  address_components: google.maps.GeocoderAddressComponent[];
  showLoader: boolean;
  targetElement: any;
  get f() { return this.formGroup.controls; }
  constructor(private formBuilder: FormBuilder,private apiService:ApiService,private router: Router,) {
    this.formGroup = this.formBuilder.group({
      "active":true,
      "address":"",
      "buyerid":JSON.parse(sessionStorage.getItem('buyerInfo')).buyerid,
      "city":"",
      "latitude":'',
      "longitude":'',
      "mobileNo":[JSON.parse(sessionStorage.getItem('buyerInfo')).mobileNo,[Validators.required]],
      "name":["",[Validators.required]],
      "pincode":"",
      "state":"",
      flatNumber:'',
      toReach:'',
    });
   }
 

  ngOnInit() {
    var buyerInfo=JSON.parse(sessionStorage.getItem('buyerInfo'));
    this.name=buyerInfo.name;
    this.mobileNo=buyerInfo.mobileNo;
    this.map=new google.maps.Map(document.getElementById('map'), {
      zoom: 4,
      mapTypeControl :false,
      mapTypeId:'roadmap',
      fullscreenControl:false,
      center:{ lat: 20.5937, lng: 78.9629}
  });
  var map=this.map;
   // Create the search box and link it to the UI element.
   const input = document.getElementById("pac-input") as HTMLInputElement;;
   let div = document.createElement('div') as HTMLInputElement;
   div.appendChild(input);   
   const searchBox = new google.maps.places.SearchBox(input);
   this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(div);
   this.map.addListener("bounds_changed", () => {
    searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
  });
  let markers: google.maps.Marker[] = [];
  searchBox.addListener("places_changed", () => {
    const places = searchBox.getPlaces();

    if (places.length == 0) {
      return;
    }

    // Clear out the old markers.
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];
  
    // For each place, get the icon, name and location.
    const bounds = new google.maps.LatLngBounds();
    places.forEach((place) => {
      var local=this;
      if (!place.geometry) {
        console.log("Returned place contains no geometry");
        return;
      }
      const icon = {
        url: place.icon='https://maps.google.com/mapfiles/ms/icons/blue-dot.png' as string,
        size: new google.maps.Size(100, 100),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(50, 50),
      };

      markers.push(
        new google.maps.Marker({
          map,
          icon,
          title: place.name,
          position: place.geometry.location,
        })
      );
        //this.address=place.formatted_address;
        this.lat=place.geometry.location.lat();
        this.long=place.geometry.location.lng();
        this.address=place.formatted_address
        this.address_components=place.address_components
        this.formatAddress();        
        //this.codeLatLng(place.geometry.location.lat(),place.geometry.location.lng());
      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    this.map.fitBounds(bounds);
  });
  this.initMap();
  }
  formatAddress() {
    for (var i = 0; i < this.address_components.length; i++) {
      for (var j = 0; j < this.address_components[i].types.length; j++) {
        if (this.address_components[i].types[j] == "postal_code") {
          this.pincode = this.address_components[i].long_name;
          break;
        }
        else if (this.address_components[i].types[j] == "locality") {
         this.city = this.address_components[i].long_name;
         break;
        }
        else if (this.address_components[i].types[j] == "administrative_area_level_1") {
          this.state = this.address_components[i].long_name;
          break;
        }
        else break;
      }
    }
  }
    onSubmit(formData) {
      formData.latitude=this.lat;
      formData.longitude=this.long;
      formData.pincode=this.pincode;
      formData.state=this.state;
      formData.city=this.city;
      if (this.formGroup.invalid) {
        console.log("INVALID form");
        return;
    }
    if(!formData.latitude){
      return;
    }
    this.showLoader=true;
    formData.address=(formData.flatNumber?(formData.flatNumber+" "):"")+formData.address+" "+(formData.toReach?(formData.toReach+" "):"");
    var obj={}
    obj=JSON.parse(JSON.stringify(formData))
    this.apiService.saveAddress(name, formData).subscribe((res: any) => {
      this.showLoader=false;
      if(res.code=="200"){
        this.router.navigate(['/checkout']);
      }
    },(err)=>{
      this.showLoader=false;
       console.log(err);
     });
  }
   initMap(): void {
    var infoWindow = new google.maps.InfoWindow();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
  
          infoWindow.setPosition(pos);
          infoWindow.setContent("Current Location");
          infoWindow.open(this.map);
          this.map.setCenter(pos);
          this.codeLatLng( position.coords.latitude,position.coords.longitude);
        },
        (e) => {
          console.log("Error Occured",e);
        }
      );
    } else {
     console.log("Browser does't support");
    }
  }
  codeLatLng(lat, lng) { 
    var geocoder = new google.maps.Geocoder(); ;
    var latlng = new google.maps.LatLng(lat, lng); 
    this.lat=lat;
    this.long=lng;
    const request = {
      location: latlng
    };
    var local=this;
    geocoder.geocode(request, function(results, status) { 
      if (status == google.maps.GeocoderStatus.OK) { 
      console.log(results) 
        if (results[1]) { 
          local.address_components=results[1].address_components;
          local.address=results[1].formatted_address;
          local.formatAddress(); 
 
        } else { 
          alert("No results found"); 
        } 
      } else { 
        alert("Geocoder failed due to: " + status); 
      } 
    }); 
  } 
  
}
