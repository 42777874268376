import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../app-config.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-encrypted',
  templateUrl: './encrypted.component.html',
  styleUrls: ['./encrypted.component.scss']
})
export class EncryptedComponent implements OnInit {
  subscription: any;

  constructor(private apiconfig:AppConfigService,private apiService:ApiService,private router: Router) { }

  ngOnInit() {
    //this.router.navigate(['/home']);
    this.subscription = this.apiconfig.getHome().subscribe(x => { 
      this.router.navigate(['/home']);
    });
  }

}
