import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { CommonServicesService } from '../common-services.service';
import { StoreService } from '../store.service';

@Component({
  selector: 'app-tnc',
  templateUrl: './tnc.component.html',
  styleUrls: ['./tnc.component.scss']
})
export class TncComponent implements OnInit {

  constructor(public storeServices:StoreService,public commonServices:CommonServicesService) { }

  ngOnInit() {
  }

}
