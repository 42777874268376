<div class="body-content">
	<div class="container" style="min-height: 450px;margin-bottom: 20px;background: white;margin-top: 15px;">
          
        <div class="row" style="margin-top: 10px;margin-bottom: 10px;" *ngIf="finalCartList.length>0">
                <div class="col-xs-12 col-sm-12 col-md-12"> 
                    <label style="font-size: 20px;">Your Cart</label>
                </div>
				<div class="col-xs-4 col-sm-4 col-md-2">
                    <app-image-auth name="cart" *ngIf="shopDetail" src="{{shopDetail.shopThumbnailImage}}" alt="Loading Image"></app-image-auth>
                    <span></span>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-3">
                    <span class="spn" *ngIf="shopDetail">{{shopDetail.shopName}} ({{shopDetail.totalItems}} Item)</span>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-7">
                    <i class="fa fa-map-marker " style="margin-right: 4px;"></i>
                    <span class="spn2" *ngIf="fullResponse"> {{fullResponse.deliveryAddress.name}} , {{fullResponse.deliveryAddress.address}}</span>
                </div>
                
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12" *ngIf="finalCartList.length>0"> 
                <label style="font-size: 15px;font-weight: 450;margin-left:-15px;">Order Detail</label>
            </div>
            <div class="cart-item col-xs-12 col-sm-12 col-md-12"  *ngFor="let item of finalCartList;let i=index">
                
				<div class="col-xs-1 col-sm-1 col-md-1" style="margin-left: -15px;top: 12px;">
                    <h4 class="title" style="text-align: center;">{{i+1}}</h4>
                </div> 
                <div class="col-xs-3 col-sm-3 col-md-3" >
                    <app-image-auth name="circl"  src="{{item.productThumbnailImage}}" alt="Loading Image"></app-image-auth>
                </div>  
                <div class="col-xs-5 col-sm-5 col-md-5" >
                   <span class="title" >{{item.productName}} </span><br>
                   <span class="price"> {{item.productSellingPrice | currency:'INR'}}</span>
                   <span class="price-strike">{{item.productMrp | currency:'INR'}}</span>
                </div> 
                <div class="col-xs-3 col-sm-3 col-md-3 plsmin"   *ngIf="item.outOfStock == false && item.productActive == true">
                    
                        <span  class="plusminus"  (click)="updateCount(item,'dsc')"><i class="fa fa-minus" ></i></span>
                   
                        <span class="plusminus">{{item.count}} </span>

                        <span  class="plusminus"  (click)="updateCount(item,'inc')"><i class="fa fa-plus" ></i> </span>

                        <span  class="plusminus2" (click)="updateCount(item,'delete')"><i class="fa fa-trash" ></i> </span>
                    <!-- <div class="col-xs-12 col-sm-12 col-md-12">
                        <div (click)="updateCount(item,'delete')"><i class="fa fa-trash" ></i></div>
                    </div> -->
                   
                    <!-- <span class="plusmius"  (click)="updateCount(item,'delete')" style="margin-top: -10px;"><i class="fa fa-trash" ></i></span> -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3"  *ngIf="item.outOfStock == true && item.productActive == true">
                    <span class="title" style="color: #ff7878">Out Of Stock </span>
                    <span class="plusmius"  (click)="updateCount(item,'delete')" ><i class="fa fa-trash" ></i></span>
                </div>
                
            </div>
            <!-- <hr> -->
                <div class="col-xs-12 col-sm-12 col-md-4 brd" style="margin-top: 10px;margin-bottom: 10px;" *ngIf="fullResponse && finalCartList.length>0">
                    <span class="spn-final">Total MRP</span>
                    <span class="spn-final-2">{{fullResponse.totalMrp | currency:'INR'}}</span>  
                    <span class="spn-final">Total Selling Price</span> 
                    <span class="spn-final-2">{{fullResponse.totalSellingPrice | currency:'INR'}}</span>
                    <span class="spn-final">Total Product Discount</span> 
                    <span class="spn-final-2">{{fullResponse.totalProductDiscount | currency:'INR'}}</span>
                    <span class="spn-final">Total Offer Discount</span> 
                    <span class="spn-final-2">{{fullResponse.totalOfferDiscount | currency:'INR'}}</span>
                    <span class="spn-final">Total Savings</span> 
                    <span class="spn-final-2">{{fullResponse.totalSavings| currency:'INR'}}</span>
                    <span class="spn-final">Delivery Charges</span>
                    <span class="spn-final-2">{{fullResponse.deliveryCharge| currency:'INR'}}</span>
                    <hr>
                    <span class="spn-final" *ngIf="shopDetail">Amount To Pay {{shopDetail.shopName}}</span> 
                    <span class="spn-final-2">{{fullResponse.netAmount | currency:'INR'}}</span>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-8" *ngIf="finalCartList.length>0"> 
                    <label style="font-size: 15px;font-weight: 450;margin-left:-15px;">Select Payment Mode :</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-8" *ngIf="fullResponse && finalCartList.length>0" style="margin-top: 10px;margin-bottom: 10px;">
                    <div class="col-xs-6 col-sm-6 col-md-4" style="line-height: 3;" *ngIf="showPOD">
                        <mat-radio-button [checked]="payOnDelivery" (click)="paymentMode('POD')">Pay On Delivery</mat-radio-button>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4"  style="line-height: 3;" *ngIf="showPG">
                    <mat-radio-button  [checked]="onlinePay" (click)="paymentMode('ONP')">Online Payment</mat-radio-button>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4" style="text-align: center;margin-top: 5px;">
                    <button disabled class="btn btn-primary"  type="submit" *ngIf="outOfStock">Remove Out Of Stock Item</button>
                    <ng-container *ngIf="postData && postData.appId">
                        <form ngNoForm #myFormPost name="myFormPost" 
                          action="https://www.cashfree.com/checkout/post/submit"
                          method="POST"
                          >
                          <input type="hidden"  name="appId" [value]="postData.appId" />
                          <input type="hidden"  name="orderId" [value]="postData.orderId" />
                          <input type="hidden"  name="orderAmount" [value]="postData.orderAmount" />
                          <input type="hidden" name="orderCurrency" [value]="postData.orderCurrency" />
                          <input type="hidden"  name="orderNote" [value]="postData.orderNote" />
                          <input type="hidden"  name="customerName" [value]="postData.customerName" />
                          <input type="hidden"  name="customerEmail" [value]="postData.customerEmail" />
                          <input type="hidden"  name="customerPhone" [value]="postData.customerPhone" />
                          <input type="hidden"  name="returnUrl" [value]="postData.returnUrl" />
                          <input type="hidden"  name="notifyUrl" [value]="postData.notifyUrl" />
                          <input type="hidden"  name="signature" [value]="postData.signature" />
                          <button class="btn btn-primary"  type="submit" *ngIf="!outOfStock && onlinePay" >Proceed To Pay : {{fullResponse.netAmount | currency:'INR'}}</button>
                          <!-- <button type="submit" >Reset</button> -->
                        </form>
                        
                      </ng-container>
                    <button class="btn btn-primary"  type="submit" *ngIf="!outOfStock && payOnDelivery" (click)="orderCall()">Place Order</button>
                    </div>

                </div> 
            
            <div class="center-cls" *ngIf="finalCartList.length<=0">
               <a style="font-size: 20px;" routerLink="/home">Add Items To Cart</a> 
            </div>      
    </div>
</div>


  
<app-loader [show]="showLoader"></app-loader>

     
        

