import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  storeDetails:any;
  storeServices:any=[];
  constructor() { }
}
